<template>
    <div class="reservation-view-page">
        <el-card shadow="always" class="table-block" style="margin-top:0px">
            <el-row style="margin:-20px;">
                <el-col :span="24">
                    <el-card shadow="never">
                        <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small"
                            style="margin-bottom:-18px;">
                            <el-form-item label="姓名:">
                                <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="clickquery" icon="el-icon-search" type="primary">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>
        <el-row :gutter="20" class="content_box">
            <el-col :span="12" class='hgt100'>
                <el-card shadow="always" class="table-block hgt100">
                    <el-table :data="tableData" ref="multipleTable" v-loading="loading"
                        :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#999' }"
                        :cell-style="{ 'text-align': 'center' }" style="width: 100%">
                        <!-- <el-table-column type="index" width="50" label="id"></el-table-column> -->
                        <el-table-column prop="noticeTitle" label="姓名"> </el-table-column>
                        <el-table-column prop="beginDate" label="岗位"> </el-table-column>
                        <el-table-column prop="endDate" width="150" label="机构"> </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
            <el-col :span="12" style='height:100%'>
                <el-card shadow="always" class="table-block hgt100">
                    <el-calendar>
                        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                        <template slot="dateCell" slot-scope="{data}">
                            <p :class="data.isSelected ? 'is-selected' : ''">
                                {{ data.day.split('-').slice(1).join('-') }} 
                            </p>
                        </template>
                    </el-calendar>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
  
<script>
import { postRequestAnthor } from '@/api/index'
export default {
    data() {
        return {
            form: {
                name: '',
            },
            loading: false,
            tableData: []
        }
    },
    created() {

    },

    methods: {
        clickquery() { }
    },
}
</script>
  
<style lang="scss" scoped>
.reservation-view-page {
    background: transparent;
    height: 100%;

    .content_box {
        height: calc(100% - 85px);
    }
}
.is-selected {
    color: #1989FA;
  }
.search>.el-form-item {
    margin-right: 13px !important;
}

.seelialog {
    margin: -20px 0;

    p {
        span {
            display: inline-block;
            width: 80px;
        }
    }
}

.Selectnum {
    padding: 6px 30px;
    border: 1px solid #BFCBF4;
    font-size: 12px;
    color: #666;
    border-radius: 5px;
    margin-bottom: 10px;
}

.right {
    flex: 1;
    height: 500px;
    margin: -30px 0;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .title {
        width: 100%;
        padding-left: 20px;
        line-height: 40px;
        border-bottom: 1px solid #ddd;
    }

    .permission {
        flex: 0.25;
        padding: 10px 10px;
    }

}

.dialog-footer {
    margin-top: 10px;
    text-align: center;
}

.hgt100 {
    height: 100%;
}

.table-block {
    margin-top: 20px;
}
</style>
  